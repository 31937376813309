import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import api from "./api";
import { APIContextProvider } from "./ApiContext";
import "./App.css";
import awsExports from "./aws-exports";
import Header from "./components/Header";
import Home from "./components/Home";
import GlobaLoader, { useAxiosLoader } from "./GlobalLoader";
import { GlobalStyle } from "./GlobalStyle";

var createHost = require("cross-domain-storage/host");
var createGuest = require("cross-domain-storage/guest");

var storageHost = createHost([
  {
    origin: "http://localhost:3000",
    allowedMethods: ["get"],
  },
  {
    origin: "https://localhost:3000",
    allowedMethods: ["get"],
  },
  {
    origin: "https://dashboard.dataappinsights.com",
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: "https://dsrw.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://dsr.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://sri-demo.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://tehrihills.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://uat-consumer-pulse.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://consumer-pulse.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://consumer-pulse-weighted.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://paramount.dataappinsights.com",
    allowedMethods: ["get"],
  },
  {
    origin: "https://paramountw.dataappinsights.com",
    allowedMethods: ["get"],
  },
]);

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export const FilterContext = React.createContext();
export const ColorPaletteContext = React.createContext();
export const FilteredQuestionListContext = React.createContext();

function App({ isPassedToWithAuthenticator, signOut, user }) {
  const [loading] = useAxiosLoader();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    const getSourceIP = async () => {
      await api.get(
        `https://9pvxvi3ned.execute-api.ap-southeast-2.amazonaws.com/prod/login-logs`,
        {
          headers: {
            "X-Source-Application-Url":
              "https://dashboard.dataappinsights.com/",
          },
        }
      );
    };
    getSourceIP();
  }, []);

  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    },
  });

  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#666666",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };

  return (
    <>
      <APIContextProvider>
        {loading ? (
          <div className="center-loader">
            <GlobaLoader />
          </div>
        ) : (
          <div className="main">
            <ThemeProvider theme={theme}>
              <Router>
                <GlobalStyle />
                <Header user={user} />
                <Routes>
                  <Route path="/" element={<Home user={user} />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </div>
        )}
      </APIContextProvider>
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  hideForgotPassword: true,
});
